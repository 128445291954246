<template>
  <div v-if="databaseBackupsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("download") }}</th>
            <th
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeDatabaseBackUps
                    .databaseBackupDelete
                )
              "
            >
              {{ $t("delete") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(databaseBackup, index) in databaseBackupsData"
            :key="databaseBackup.databaseBackupToken"
          >
            <td>{{ ++index }}</td>
            <td>
              <span class="cut-1line">{{
                isDataExist(databaseBackup.databaseBackupNameCurrent)
              }}</span>
            </td>
            <td>
              {{ isDataExist(databaseBackup.databaseBackupDescriptionCurrent) }}
            </td>
            <td>
              {{ isDataExist(databaseBackup.databaseBackupNotes) }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  databaseBackup.actionDate,
                  databaseBackup.actionTime
                )
              }}
            </td>
            <td>
              <button
                class="btn p-0"
                :title="$t('download')"
                @click="downloadDatabaseBackup(databaseBackup)"
              >
                <img src="@/assets/images/downloadDB.svg" class="icon-lg" />
              </button>
            </td>

            <td
              v-if="
                checkPrivilege(
                  usersEstablishmentPrivilegeData.usersPrivilegeDatabaseBackUps
                    .databaseBackupDelete
                )
              "
            >
              <button
                v-b-modal.DatabaseBackupDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setDatabaseBackupData(databaseBackup)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import UsersEstablishmentPrivilege from "@/models/privileges/establishmentPrivileges/UsersEstablishmentPrivilege";
import {
  formateDateTimeLang,
  isDataExist,
  checkPrivilege,
} from "@/utils/functions";

export default {
  name: "DatabaseBackupsTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      usersEstablishmentPrivilegeData:
        this.$store.getters.usersEstablishmentPrivilegeData ||
        new UsersEstablishmentPrivilege(),
    };
  },
  props: ["databaseBackupsData", "databaseBackupData", "pagination"],
  methods: {
    checkPrivilege,
    isDataExist,
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    setDatabaseBackupData(databaseBackupData) {
      this.$emit("setDatabaseBackupData", databaseBackupData);
    },
    downloadDatabaseBackup(databaseBackupData) {
      this.$emit("downloadDatabaseBackup", databaseBackupData);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
