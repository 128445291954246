var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.databaseBackupsData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("description")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))]),_c('th',[_vm._v(_vm._s(_vm.$t("date")))]),_c('th',[_vm._v(_vm._s(_vm.$t("download")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData.usersPrivilegeDatabaseBackUps
                  .databaseBackupDelete
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.databaseBackupsData),function(databaseBackup,index){return _c('tr',{key:databaseBackup.databaseBackupToken},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_c('span',{staticClass:"cut-1line"},[_vm._v(_vm._s(_vm.isDataExist(databaseBackup.databaseBackupNameCurrent)))])]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(databaseBackup.databaseBackupDescriptionCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(databaseBackup.databaseBackupNotes))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formateDateTimeLang( databaseBackup.actionDate, databaseBackup.actionTime ))+" ")]),_c('td',[_c('button',{staticClass:"btn p-0",attrs:{"title":_vm.$t('download')},on:{"click":function($event){return _vm.downloadDatabaseBackup(databaseBackup)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/downloadDB.svg")}})])]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData.usersPrivilegeDatabaseBackUps
                  .databaseBackupDelete
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.DatabaseBackupDelete",modifiers:{"DatabaseBackupDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setDatabaseBackupData(databaseBackup)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }